import React from 'react';
import SinglePoem from '../../../components/singlePoem';
import Paper from '@mui/material/Paper'

export default function Poem() {
  return (
    <SinglePoem title="Catacombs" >
        <p>
            The narrow slab above the door<br></br>
            in dire masonry reads:<br></br><br></br>

            <div style={{maxWidth:'400px', padding: '20px'}}>
                Arrete! C’est ici l’empire DE LA MORT<br></br>
                sTOP! tHIS is THE EMPIRE OF dEATH<br></br><br></br>
            </div>

            And so, a hundred stairs below<br></br>
            the surface of Montparnasse and<br></br> 
            past the lintel, <br></br>
            where Charles the Tenth once held<br></br>
            his orgiastic revels, lanterns casting<br></br>
            elongated shadows of couplings across the ceilings,<br></br>
            and where the Brave of France<br></br>
            hatched sweet requital on their Nazi hosts,<br></br>
            living unwashed, unlawful, and<br></br>
            baptized in their cause by the seeping Parisian waters<br></br>
            dripping from those ceilings onto their Gallic heads,<br></br>
            and dripping onto bones piled in careful stacks,<br></br>
            human bones, transported centuries before on carts <br></br>
            at night from Les Halles cemeteries <br></br>
            to this underground sepulcher – <br></br>
            stacks of femur and tibia,<br></br>
            dotted with the vacant stare and ever-locked grimace <br></br>
            of skulls – millions here, <br></br>
            hundreds to the fore – <br></br>
            the walls, patterns and textures borne of bones <br></br>
            for miles – a morbid display  <br></br>
            of the futile bauble of individuality –<br></br>
            I breathe in the dusting motes of<br></br>
            six million who came before –<br></br>
            and I close my eyes to rest them from this realization.<br></br>
            And opening them again, I sit, <br></br>
            hours later, in a Les Halles club –<br></br>
            cigarette hazes threading the space –<br></br>
            dim, pinkish light<br></br>
            casting its faint pinkness on skins –<br></br>
            a bumping, deadly dance-beat<br></br>
            rippling the smoke, the pink,<br></br>
            with its sonic waves of requiem –<br></br>
            the locked smiles of skulls and vacuous, searching sockets<br></br>
            hidden<br></br>
            beneath the fleshy masses of lips and cheeks and lids,<br></br>
            hundred to the fore,<br></br>
            all lining the walls in this new, uncautioning empire –<br></br>
            the carts, <br></br>
            with their ghostly pilots,<br></br> 
            just outside,<br></br>
            waiting to load again<br></br>
            and carry back to Montparnasse.<br></br>
        </p>
    </SinglePoem>
      );
}

